import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Switch, HashRouter as Router, Route } from "react-router-dom";
import { Home } from "./pages/HomePage/Home.js";
import About from "./pages/AboutPage/About.js";
import { Contact } from "./pages/ContactPage/Contact.js";

function App() {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route
            path={process.env.PUBLIC_URL + "/contact"}
            component={Contact}
          />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
