import React from "react";
import "./Nav.css";
//import Navbar from "../../Components/Navbar.js";

export const Navigation = () => {
  return (
    <div>
      <nav>
        <input type="checkbox" id="nav" class="hidden" />
        <label for="nav" class="nav-btn">
          <i></i>
          <i></i>
          <i></i>
        </label>
        <div class="logo">
          <a href="#">Koaan Studios</a>
        </div>
        <div class="nav-wrapper">
          <ul>
            <li>
              <a href="https://koaanstudios.com/about/">About</a>
            </li>
            <li>
              <a href="https://koaanstudios.com/courses/">Contact</a>
            </li>
            <li>
              <a href="https://koaanstudios.com/donate/">Press Kit</a>
            </li>
            <li>
              <a href="#">Social</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navigation;
