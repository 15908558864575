import React from "react";
import WideImg from "../../assets/wide.png";
import TallImg from "../../assets/TALL.png";
//import Navbar from "../../Components/Navbar.js";
import "./AboutPage.css";

export const About = () => {
  return (
    <div className="aboutPage">
      <div className="Navbox"></div>

      <div className=".hero-image">
        <img src={WideImg} className="mainImg" />
        <img src={TallImg} className="phoneImg" />
        <h1>about</h1>
      </div>
    </div>
  );
};

export default About;
