import React from "react";
import Navigationbar from "../../Components/Nav.js";
import "./Homestyle.css";
import WideImg from "../../assets/wide.png";
import TallImg from "../../assets/TALL.png";
import dynastytxt from "../../assets/dynastytxt.png";
import Contactblock from "./Home-2";
import Block2 from "./HomeBolck3";
export const Home = () => {
  return (
    <div className="HomePage">
      <div className=".hero-image">
        <img src={WideImg} className="mainImg" />
        <img src={TallImg} className="phoneImg" />
      </div>
      <div className="txt">
        <h1>Introducing Dynasty</h1>

        <a
          href="https://www.youtube.com/channel/UCHUex_gDhIY1Kge-cBxJyuQ"
          class="share-button"
        >
          <div className="btnbox">subscribe</div>
        </a>
      </div>

      <h1>hello</h1>
      <h1>hello</h1>
      <h1>hello</h1>
      <h1>hello</h1>

      <Navigationbar />
    </div>
  );
};

export default Home;
